import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const StyledContainer = styled.div`
  max-width: 1280px;
  display: flex;
  justify-content: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  ${respondTo.desktop`
    max-width: 1024px;
  `}
`;
