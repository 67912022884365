import styled, { css } from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.footer`
  position: relative;
  min-height: 90vh;
  padding: 200px 0 120px 0;
  ${respondTo.smallMobile`
    padding: 100px 0;
  `}
`;

export const Img = styled.img`
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
  background-color: rgba(0,0,0,.2);
`;

export const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.12%, #000000 100%);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 100px;
  ${respondTo.mobile`
    grid-template-columns: 1fr;
  `}
  ${respondTo.smallMobile`
    grid-template-columns: 1fr;
  `}
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${respondTo.mobile`
    justify-content: center;
    width: auto;
  `}
  ${respondTo.smallMobile`
    ${({ isForm }) => isForm && css`
      justify-content: center;
    `}
  `}
`;

export const Title = styled.h2`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 110%;
  /* identical to box height, or 64px */

  //display: flex;
  //align-items: center;
  text-align: center;
  ${respondTo.mobile`
    justify-content: center;
    display: block;
  `}
  ${respondTo.smallMobile`
    justify-content: center;
    display: block;
    font-size: 36px;
  `}

  /* Primary/White */

  color: #FFFFFF;
  margin-bottom: 50px;
`;

export const InfoList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 75%;
  ${respondTo.mobile`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;
  `}
`;

export const InfoListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 26px;
  ${respondTo.mobile`
    // justify-content: center;
  `}
  ${respondTo.smallMobile`
    // align-items: end;
  `}
  ${({ isButton }) => isButton && css`
    margin-top: 56px;
  `}
`;



export const InfoListItemIcon = styled.div`
  //margin-right: 28px;
  //margin-bottom: 10px;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  margin-bottom: 20px;
  width: 65px;
`;

export const InfoListItemText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #FFFFFF;
  text-align: center;
  ${respondTo.smallMobile`
    font-size: 16px;
  `}
  
  ${({ isButton }) => isButton && css`
    background: #DD3330 !important;
    display: flex;
    width: 270px;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    height: 70px;
    border: none;
    text-decoration: none;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    /* identical to box height, or 90% */
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: .1s ease;

    &:hover {
      background-color: #C00300;
    }

    &:focus {
      background-color: #FC1A16;
    }
  `}
`;

export const FooterCopyrightWrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
  left: 0;
  z-index: 2;
  border-top: 1px solid rgba(255, 255, 255, .2);
`;

export const Copyright = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  display: flex;
  align-items: center;

  /* Primary/White */

  color: #FFFFFF;
  opacity: 0.5;
  
  ${respondTo.smallMobile`
    font-size: 14px;
  `}
`;

export const RedText = styled.p`
  //font-size: 14px;
  color: #DD3330;
  text-decoration: none;
`;
