import styled from 'styled-components';

export const StyledGradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.12%, #000000 100%);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
