import styled, { css } from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.div`
  margin-top: 50px;
`;

export const SwitchTabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1370px;
  min-width: 1000px;
  padding: 0 40px;
  ${respondTo.desktop`
    min-width: 1000px;
  `}
  ${respondTo.mobile`
    min-width: 320px;
    max-width: 100%;
  `}
  ${respondTo.smallMobile`
    min-width: auto;
    max-width: 100%;
  `}
`;

export const SwitchTabItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  font-family: ${({ active }) => (active ? 'Rubik' : 'Rubik')}, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  &:last-child {
    margin-right: 0;
  }
  ${respondTo.mobile`
    font-size: 16px;
    margin-right: 24px;
  `}
  ${respondTo.smallMobile`
    font-size: 12px;
    margin-right: 20px;
  `}
  /* or 26px */

  text-align: center;
  color: #9c9ca5;
  cursor: pointer;
  transition: 0.3s ease all;

  &:focus,
  &:hover {
    color: #676767;
  }

  &:after {
    content: '';
    width: 97px;
    height: 7px;
    background: #dd3330;
    position: absolute;
    transition: 0.3s ease all;
    left: 0;
    right: 0;
    bottom: -17px;
    margin: auto;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }

  ${({ active }) =>
    active &&
    css`
      color: #19161d;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
  //width: 100%;
  min-width: 1180px;
  width: 100%;
  ${respondTo.desktop`
    min-width: 980px;
  `}
  ${respondTo.mobile`
    flex-direction: column;
    align-items: center;
    min-width: auto;
    max-width: 100%;
  `}
  ${respondTo.smallMobile`
    flex-direction: column;
    align-items: center;
    min-width: auto;
    max-width: 100%;
  `}
`;

export const ContentItem = styled.div`
  flex: 1;
  max-width: ${({ maxW = 'auto' }) => maxW};
  min-width: 600px;
  ${respondTo.desktop`
    min-width: 400px;
    max-width: 400px;
  `}
  ${respondTo.mobile`
    margin-bottom: 20px
  `}
  ${respondTo.smallMobile`
    min-width: auto;
    max-width: 100%;
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${respondTo.mobile`
     text-align: center;
  `}
  ${({ rightSide }) =>
    rightSide &&
    css`
      text-align: right;
      display: block;
      min-width: 480px;
      max-width: 480px;
      ${respondTo.desktop`
        min-width: auto;
        max-width: auto;
      `}
      ${respondTo.mobile`
        text-align: center;
        min-width: 630px;
        max-width: 630px;
      `}
      ${respondTo.smallMobile`
        min-width: auto;
        max-width: 100%;
      `}
    `}
`;

export const ContentTitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 110%;
  /* or 35px */

  display: flex;
  align-items: center;

  color: #2a2a2a;
  ${respondTo.mobile`
    display: block;
  `}
`;

export const ContentText = styled.p`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  margin-top: ${({ mt = 0 }) => mt}px;
  margin-bottom: ${({ mb = 0 }) => mb}px;

  color: #676767;
`;

export const ContextImage = styled.img`
  width: 480px;
  height: 480px;
  ${respondTo.desktop`
        width: 100%;
        height: auto;
      `}
  ${respondTo.smallMobile`
        min-width: auto;
        max-width: 100%;
      `}
`;

export const ContentTextItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: baseline;
  span {
    width: 10px;
    height: 10px;
    background-color: #dd3330;
    margin-right: 20px;
  }
  p {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    color: #676767;
  }
`;
