import React from 'react';

import { ReactComponent as LogoImage2 } from 'assets/images/logo_2.svg';
import { ReactComponent as LogoImage3 } from 'assets/images/logo_3.svg';
import { ReactComponent as LogoImage } from 'assets/images/logo_1.svg';

import { FirstLogo, LogoBox } from './styled';

const HeaderLogo = () => {
  return (
    <LogoBox>
      <FirstLogo>
        <LogoImage2 />
        <LogoImage3 style={{ position: 'absolute', left: '23%', top: '23%' }} />
      </FirstLogo>
      <LogoImage />
    </LogoBox>
  );
};

export default HeaderLogo;
