import React from 'react';
import WelcomeImage from 'assets/images/welcome_bg_gradient.png';
import PartnerImg from 'assets/images/partner_img.svg';
import WelcomeNew from 'assets/images/ritail_new_welcome.svg';
import Lenovo from 'assets/images/lenovo.png';
import PrimaryButton from 'components/atoms/Button';
import { domIds } from 'common/constants';
import { StyledGradient } from 'components/atoms/Gradient';

import {
  Wrapper,
  Img,
  Title,
  Text,
  PartnerBlock,
  PartnerText,
  PartnerImage,
  Content,
  ImagesWrapper,
} from './styled';

const WelcomeSection = () => {
  return (
    <Wrapper id={domIds.welcome}>
      <Img src={WelcomeImage} alt='header' />
      {/*<StyledGradient />*/}
      <Content>
        <Title>IT Solutions for the Retail Industry</Title>
        <Text>
          With 20+ years expertise in the cloud and software development, <br />{' '}
          our team can provide both ready-made and custom services.
        </Text>
        {/*<PrimaryButton w='317px'>Request a quote</PrimaryButton>*/}
        <PartnerBlock>
          <PartnerText>Partners</PartnerText>
          <ImagesWrapper>
            <PartnerImage src={WelcomeNew} alt='partner' mobileMb={0} />
            {/*<PartnerImage src={Lenovo} alt='lenovo' ml={20} />*/}
          </ImagesWrapper>
        </PartnerBlock>
      </Content>
    </Wrapper>
  );
};

export default WelcomeSection;
