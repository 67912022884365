import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  transition: 0.3s ease all;
  height: 70px;
  background-color: ${({ showBg }) =>
    showBg ? 'rgba(25, 22, 29, .9)' : 'transparent'};
  //padding: 0 26px;
  z-index: 997;
  width: 100vw;
  left: 0;
  right: 0;
  ${respondTo.largeDesktop`
    // padding: 0 90px;
  `}
  ${respondTo.mobile`
    padding: 0 18px;
  `}
  ${respondTo.smallMobile`
    // padding: 0 16px;
  `}
`;

export const HamburgerWrapper = styled.div`
  display: none;
  color: white;
  z-index: 998;
  ${respondTo.tablets`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `}
`;

export const WrapperBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SecondLogo = styled.div`
  background-color: #dd3330;
`;

export const LangBlock = styled.div`
  display: flex;
  align-items: center;
  z-index: 998;
  ${respondTo.tablets`
    flex: 1;
    justify-content: center;
  `}
`;

export const LangText = styled.span`
  margin-right: 5px;
  color: #ededed;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
`;
