import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const LogoBox = styled.a`
  display: flex;
  align-items: center;
  z-index: 999;
  ${respondTo.tablets`
      flex: 1;
  `}
`;


export const FirstLogo = styled.div`
  margin-right: 16px;
  position: relative;
  height: 100%;
`;
