import React, { useState, useCallback } from 'react';
import { StyledSubTitle, StyledTitle } from 'components/atoms/Titles';
import { StyledContainer } from 'components/atoms/Container/styled';
import CustomerSwitcher from 'components/molecules/CustomersSwitcher';
import { domIds } from 'common/constants';

import { Wrapper } from './styled';

const CustomersSection = () => {
  const [activeKey, setActiveKey] = useState('web_agencies');

  const onClick = useCallback(key => {
    setActiveKey(key);
  }, []);

  return (
    <Wrapper id={domIds.customers}>
      <StyledSubTitle mb={12}>CUSTOMERS</StyledSubTitle>
      <StyledTitle>
        Full Lifecycle Software <br /> Services
      </StyledTitle>
      <StyledContainer>
        <CustomerSwitcher activeKey={activeKey} onClick={onClick} />
      </StyledContainer>
    </Wrapper>
  );
};

export default CustomersSection;
