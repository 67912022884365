import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const StyledButton = styled.button`
  outline: none;
  background: #DD3330;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ w }) => w || '259px'};
  padding: 0 60px;
  height: 70px;
  border: none;
  text-decoration: none;

  //font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
  /* identical to box height, or 90% */
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  transition: .1s ease;
  
  &:hover {
    background-color: #C00300;
  }

  &:focus {
    background-color: #FC1A16;
  }
  
  ${respondTo.smallMobile`
    width: 100%
  `}
`;