import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const StyledTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 110%;
  /* identical to box height, or 64px */
  text-align: ${({ textCenter = true }) => (textCenter ? 'center' : 'left')};
  margin: ${({ mt = 0, mr = 0, mb = 0, ml = 0 }) =>
    `${mt}px ${mr}px ${mb}px ${ml}px`};

  color: ${({ color = '#19161D' }) => color};
  ${respondTo.smallMobile`
    font-size: 24px;
  `}
`;

export const StyledSubTitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */
  margin: ${({ mt = 0, mr = 0, mb = 0, ml = 0 }) =>
    `${mt}px ${mr}px ${mb}px ${ml}px`};

  text-transform: uppercase;
  text-align: ${({ textCenter = true }) => (textCenter ? 'center' : 'left')};

  color: ${({ color = '#676767' }) => color};
`;
