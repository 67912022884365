import React, { useState, useCallback } from 'react';
import { domIds } from 'common/constants';
import { StyledSubTitle, StyledTitle } from 'components/atoms/Titles';
import TabSwitcher from 'components/molecules/TabSwitcher';

import { Wrapper } from './styled';

const ExpertiseSection = () => {
  const [activeKey, setActiveKey] = useState(2);

  const onClickTab = useCallback(key => {
    setActiveKey(key);
  }, []);

  return (
    <Wrapper id={domIds.expertise}>
      <StyledSubTitle mb={12}>EXPERTISE</StyledSubTitle>
      <StyledTitle>Our business focus</StyledTitle>
      <TabSwitcher onClick={onClickTab} activeKey={activeKey} />

    </Wrapper>
  );
};

export default ExpertiseSection;
