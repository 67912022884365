import styled, { css } from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  ${respondTo.mobile`
    grid-template-columns: 1fr;
  `}
  ${respondTo.smallMobile`
    display: flex;
    flex-direction: column;
  `}
`;

export const SwitchBlockItem = styled.div`
  position: relative;
  ${({ isImage }) =>
    isImage &&
    css`
      text-align: right;
      ${respondTo.mobile`
        text-align: center;
      `}
    `}
`;

export const SwitchItemWrapper = styled.div`
  margin-bottom: 32px;
  transition: 0.2s ease all;
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    transition: 0.2s ease all;
    ${({ active }) =>
      active &&
      css`
        background-color: #dd3330;
      `}
  }
  ${({ active }) =>
    active &&
    css`
      background: #fbfbfb;
      padding-left: 24px;
    `}
`;

export const SwitchItemIcon = styled.img`
  margin-right: 24px;
  
`;

export const SwitchItem = styled.div`
  position: relative;
  display: flex;
  align-items: self-start;
  padding: 24px 10px 19px 24px;${({ active }) =>
        active &&
        css`
          img {
            path {
              fill: #dd3330;
            }
            fill: #dd3330;
          }
    `}
`;

export const SwitchItemTitle = styled.h5`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  /* or 26px */

  display: flex;
  align-items: center;

  /* Secondary/Mid Night/Text Main */

  color: #19161d;
`;

export const SwitchItemDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #676767;
  margin-top: 10px;
`;

export const SwitchItemImage = styled.img`
  width: 100%;
  ${respondTo.smallMobile`
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;
