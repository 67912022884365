import styled, { css } from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 100px 0;
  ${respondTo.smallMobile`
    padding: 75px 0;
  `}
`;

export const Img = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  height: 100%;
  z-index: 2;
  position: relative;
`;

export const ProjectsWrapper = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 300px 300px 300px;
  gap: 50px 100px;
  justify-content: space-between;
  ${({ second }) =>
    second &&
    css`
      justify-content: center;
      grid-template-columns: 300px 300px;
    `}
  ${respondTo.desktop`
    gap: 25px 50px;
    grid-template-columns: 250px 250px 250px;
    ${({ second }) =>
      second &&
      css`
        justify-content: center;
        gap: 35px 75px;
        grid-template-columns: 250px 250px;
      `}
  `}
  ${respondTo.mobile`
    gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
    ${({ second }) =>
      second &&
      css`
        justify-content: center;
        grid-template-columns: 1fr 1fr;
      `}
  `}
  ${respondTo.smallMobile`
    display: flex;
    flex-direction: column;
  `}
`;

export const ProjectItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProjectImage = styled.div`
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

export const ProjectItemTitle = styled.div`
  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */

    //display: flex;
    //align-items: center;
    text-align: center;

    /* Primary/White */

    color: #ffffff;
    margin-bottom: 16px;
  }
`;

export const ProjectItemDescription = styled.div`
  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

    text-align: center;

    /* BCBCBC */

    color: #bcbcbc;
  }
`;
