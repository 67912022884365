import React, { Fragment, useState, useCallback } from 'react';

import EnterpriseImg from 'assets/images/enterprise_working.svg';
import ManageCloudImg from 'assets/images/manage_cloud.svg';
import SoftwareImg from 'assets/images/software_dev.svg';
import LoadingImage from 'assets/images/image_loading.svg';

import { StyledContainer } from 'components/atoms/Container/styled';

import {
  SwitchTabItem,
  Wrapper,
  SwitchTabWrapper,
  ContentText,
  ContentTitle,
  ContentWrapper,
  ContextImage,
  ContentItem,
  ContentTextItem,
} from './styled';

const getTitle = (key) => {
  switch (key) {
    case 1:
      return (
        <ContentTitle>
          Next Generation of Security that is tightly integrated with networking
          for edges.
        </ContentTitle>
      );
    case 2:
      return <ContentTitle>Real Estate Software Development</ContentTitle>;
    case 3:
      return (
        <ContentTitle>
          We offer a broad portfolio of managed services to help your company:
        </ContentTitle>
      );
    default:
      return null;
  }
};

const renderContentText = (key) => {
  switch (key) {
    case 1:
      return (
        <Fragment>
          <ContentText mt={30} mb={20}>
            Security and resiliency are fundamental components for every <br />
            enterprise: we protect your data and information with our business{' '}
            <br />
            continuity solutions.
          </ContentText>
          <ContentText>
            Thanks to our experience, consolidated through important <br />
            partnerships, and our advanced technologies, we can offer high{' '}
            <br />
            levels of security against the countless threats of the Internet.
          </ContentText>
        </Fragment>
      );
    case 2:
      return (
        <Fragment>
          <ContentText mt={30} mb={20}>
            Thanks to our network of skilled developers, we have a solid base of{' '}
            <br />
            ready-made solutions which can be tailored to your business needs.{' '}
          </ContentText>
          <ContentText>
            All our software is cloud based and does not require any additional
            infrastructure to be run.
          </ContentText>
        </Fragment>
      );
    case 3:
      return (
        <ContentText mt={30}>
          <ContentTextItem>
            <span />
            <p>Reduce startup costs and IT infrastructure management costs</p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>Have access to constantly updated and optimised solutions</p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>
              Accelerate the experimentation of new business thanks to the{' '}
              <br />
              data-driven logic
            </p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>Take full advantage of only really useful applications</p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>
              Enjoy greater flexibility and freedom in switching between <br />
              providers
            </p>
          </ContentTextItem>
        </ContentText>
      );
    default:
      return null;
  }
};

const getImage = (key) => {
  switch (key) {
    case 1:
      return EnterpriseImg;
    case 2:
      return SoftwareImg;
    case 3:
      return ManageCloudImg;
    default:
      return null;
  }
};

const TabSwitcher = ({ onClick, activeKey }) => {
  const [loadedStatuses, setLoadedStatuses] = useState({
    1: false,
    2: false,
    3: false,
  });

  const onLoading = useCallback((key) => {
    setLoadedStatuses((state) => ({
      ...state,
      [key]: true,
    }));
  }, []);

  return (
    <Wrapper>
      <StyledContainer>
        <SwitchTabWrapper>
          <SwitchTabItem
            active={activeKey === 1}
            role='presentation'
            onClick={() => onClick(1)}
          >
            <span>Enterprise Networking</span>
          </SwitchTabItem>
          <SwitchTabItem
            active={activeKey === 2}
            role='presentation'
            onClick={() => onClick(2)}
          >
            <span>Software Development</span>
          </SwitchTabItem>
          <SwitchTabItem
            active={activeKey === 3}
            role='presentation'
            onClick={() => onClick(3)}
          >
            <span>Managed Cloud</span>
          </SwitchTabItem>
        </SwitchTabWrapper>
      </StyledContainer>
      <StyledContainer>
        <ContentWrapper>
          <ContentItem maxW='630px'>
            {getTitle(activeKey)} {renderContentText(activeKey)}
          </ContentItem>
          <ContentItem rightSide={true}>
            <ContextImage
              src={
                loadedStatuses[activeKey] ? getImage(activeKey) : LoadingImage
              }
              onLoad={() => onLoading(activeKey)}
            />
          </ContentItem>
        </ContentWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default TabSwitcher;
