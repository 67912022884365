import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';
import { Link } from 'react-scroll';

export const Navigation = styled.nav`
  ${respondTo.tablets`
    position: fixed;
    width: 100vw;
    height: 100vh;
    transition: .3s ease all;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    top: 0;
    left: ${({ open }) => (open ? 0 : '100vw')};
    z-index: 998;
    background: linear-gradient(
        205.36deg,
        #131321 22.09%,
        rgba(18, 18, 31, 0) 75.4%
      ),
      linear-gradient(90.45deg, #10101b 8.85%, rgba(21, 21, 36, 0) 99.65%),
      linear-gradient(179.19deg, #0e0e18 0.69%, rgba(10, 10, 17, 0) 99.3%);
  `}
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  ${respondTo.tablets`
    margin-top: 90px;
    flex-direction: column;
    a {
      margin-bottom: 40px;
      text-align: center;
      span {
        width: 100%;
      }
    }
  `}
`;

export const ListItem = styled(Link)`
  margin-right: 34px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ededed;
  ${respondTo.tablets`
    // color: #676767;
    font-size: 38px;
    line-height: unset;
  `}
`;
