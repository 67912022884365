import React, { useState, useEffect, useCallback } from 'react';
import HeaderLogo from 'components/atoms/HeaderLogo';
import HeaderNav from 'components/molecules/HeaderNav';

import { ReactComponent as EngFlagImg } from 'assets/images/eng-flag.svg';
import { StyledContainer } from 'components/atoms/Container/styled';

import {
  Wrapper,
  LangBlock,
  LangText,
  WrapperBox,
  HamburgerWrapper,
} from './styled';

const Header = () => {
  const [showBg, setShowBg] = useState(false);
  const [open, setOpen] = useState(false);

  const detectType = useCallback((e) => {
    const windowScrolledY = window.scrollY;
    const windowHeight = window.innerHeight;
    setShowBg(windowScrolledY > windowHeight - 10);
  }, []);

  useEffect(() => {
    detectType();
    window.addEventListener('scroll', detectType, true);
    return () => {
      window.removeEventListener('scroll', detectType, true);
    };
  }, []);

  const onClickMenu = useCallback(() => {
    setOpen(s => !s);
  }, []);

  return (
    <Wrapper showBg={showBg}>
      <StyledContainer style={{ height: '100%' }}>
        <WrapperBox>
          <HeaderLogo open={open} />
          <HeaderNav open={open} onClose={() => setOpen(false)} />
          <LangBlock>
            <LangText>Eng</LangText>
            <EngFlagImg />
          </LangBlock>
          <HamburgerWrapper>
            <div
              role='presentation'
              onClick={onClickMenu}
              className={`hamburger hamburger--elastic ${
                open ? 'is-active' : ''
              }`}
            >
              <div className='hamburger-box'>
                <div className='hamburger-inner' />
              </div>
            </div>
          </HamburgerWrapper>
        </WrapperBox>
      </StyledContainer>
    </Wrapper>
  );
};

export default Header;
