import React from 'react';
import { domIds } from 'common/constants';
import { StyledSubTitle, StyledTitle } from 'components/atoms/Titles';
import { StyledContainer } from 'components/atoms/Container/styled';

import RitailIcon from 'assets/images/icons/Logo Ritail.app.svg';
import RitailShopIcon from 'assets/images/my_pr_bottom.b42976a9.svg';
import WfiIcon from 'assets/images/icons/wfi.svg';
import MyPriorityTopIcon from 'assets/images/icons/my_pr_top.svg';
import MyPriorityBottomIcon from 'assets/images/icons/my_pr_bottom.svg';
import RimailIcon from 'assets/images/icons/Rimail-logo 1.svg';
import UpBackIcon from 'assets/images/icons/upback.svg';
import ProjectBgImage from 'assets/images/projects_bg.png';

import {
  Wrapper,
  Img,
  Content,
  ProjectImage,
  ProjectItem,
  ProjectItemDescription,
  ProjectItemTitle,
  ProjectsWrapper,
} from './styled';

const projectsFirstLine = [
  {
    id: 1,
    icon: RitailIcon,
    title: 'Ritail.app',
    description:
      'A comprehensive suite of business tools and analytics for shopping malls and premium outlets',
  },
  {
    id: 2,
    icon: WfiIcon,
    title: 'Wfi',
    url: 'https://admin.wfi.ai',
    description: 'A guest Wi-Fi manager with advanced analytics engine',
  },
  {
    id: 3,
    icon: [MyPriorityTopIcon, MyPriorityBottomIcon],
    title: 'Mypriority',
    url: 'https://www.mypriority.shop/',
    description: 'A booking system and queue manager for priority access',
  },
];

const projectsSecondLine = [
  {
    id: 5,
    icon: [RitailIcon, RitailShopIcon],
    title: 'Ritail.shop',
    description: [
      'Coming Soon!',
      <br key='some' />,
      'Local Retail goes online',
    ],
  },
  {
    id: 4,
    icon: RimailIcon,
    title: 'Rimail',
    description: ['An advanced multichannel marketing campaign manager'],
    url: 'https://rimail.app',
    // 'Coming Soon! An advanced multichannel marketing campaign manager',
  },
  {
    id: 5,
    icon: UpBackIcon,
    title: 'Upback',
    description: [
      'Coming Soon!',
      <br key='some' />,
      'An enterprise database backup service for the retail industry',
    ],
  },
];

const ProjectsSection = () => {
  return (
    <Wrapper id={domIds.projects}>
      <Img src={ProjectBgImage} alt='projects' />
      <Content>
        <StyledSubTitle mb={12} color='#BCBCBC'>
          PROJECTS
        </StyledSubTitle>
        <StyledTitle color='#FFFFFF'>
          Ready-made Enterprise <br /> Saas platforms
        </StyledTitle>
        <StyledContainer style={{ display: 'block' }}>
          <ProjectsWrapper>
            {projectsFirstLine.map((i) => (
              <ProjectItem key={i.id}>
                <ProjectImage
                  as={i.url ? 'a' : 'div'}
                  href={i.url && i.url}
                  target='_blank'
                  rel={i.url && 'noreferrer noopener'}
                >
                  {Array.isArray(i.icon) ? (
                    i.icon.map((icon, index) => (
                      <img key={index.toString()} src={icon} alt={i.title} />
                    ))
                  ) : (
                    <img src={i.icon} alt={i.title} />
                  )}
                </ProjectImage>
                <ProjectItemTitle>
                  <h5>{i.title}</h5>
                </ProjectItemTitle>
                <ProjectItemDescription>
                  <p>{i.description}</p>
                </ProjectItemDescription>
              </ProjectItem>
            ))}
          </ProjectsWrapper>
          {/*<ProjectsWrapper second={true}>*/}
          <ProjectsWrapper second={false}>
            {projectsSecondLine.map((i) => (
              <ProjectItem key={i.id}>
                <ProjectImage
                  as={i.url ? 'a' : 'div'}
                  href={i.url && i.url}
                  target='_blank'
                  rel={i.url && 'noreferrer noopener'}
                >
                  {Array.isArray(i.icon) ? (
                    i.icon.map((icon, index) => (
                      <img key={index.toString()} src={icon} alt={i.title} />
                    ))
                  ) : (
                    <img src={i.icon} alt={i.title} />
                  )}{' '}
                </ProjectImage>
                <ProjectItemTitle>
                  <h5>{i.title}</h5>
                </ProjectItemTitle>
                <ProjectItemDescription>
                  <p>{i.description}</p>
                </ProjectItemDescription>
              </ProjectItem>
            ))}
          </ProjectsWrapper>
        </StyledContainer>
      </Content>
    </Wrapper>
  );
};

export default ProjectsSection;
