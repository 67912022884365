import Header from 'components/organisms/Header';
import WelcomeSection from 'components/organisms/sections/Welcome';
import ExpertiseSection from 'components/organisms/sections/Expertise';
import ProjectsSection from 'components/organisms/sections/Projects';
import CustomersSection from 'components/organisms/sections/Customers';
import Footer from 'components/organisms/Footer';

const App = () => {
  return (
    <div className='App'>
      <Header />
      <main>
        <WelcomeSection />
        <ExpertiseSection />
        <ProjectsSection />
        <CustomersSection />
      </main>
      <Footer />
    </div>
  );
};

export default App;
