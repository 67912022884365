import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.section`
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  
  button {
    width: auto;
  }
`;

export const Img = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: 1;
`;

export const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  /* or 32px */

  display: flex;
  align-items: center;
  text-align: center;
  
  ${respondTo.mobile`
    font-size: 18px;
  `}

  ${respondTo.smallMobile`
    font-size: 16px;
    br {
      display: none;
    }
  `}

  /* Secondary/Light Grey/Text for Menu and Header */

  color: #EDEDED;
  max-width: 590px;
  margin-top: 20px;
  margin-bottom: 45px;
`;

export const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 105%;
  /* or 76px */

  display: flex;
  align-items: center;
  text-align: center;
  max-width: 880px;
  margin: 0;
  color: #FFFFFF;

  ${respondTo.mobile`
    font-size: 46px;
  `}

  ${respondTo.smallMobile`
    font-size: 36px;
  `}
`;

export const PartnerText = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 28px */

  text-align: center;
  text-transform: uppercase;

  color: #EDEDED;
  opacity: 0.5;
  margin-bottom: 10px;
`;

export const PartnerImage = styled.img`
  margin-left: ${({ ml = 0 }) => ml}px;
  margin-bottom: ${({ mb = 0 }) => mb}px;
  ${respondTo.tablets`
    margin-bottom: ${({ mobileMb = 0 }) => mobileMb}px;
  `}
`;

export const ImagesWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${respondTo.tablets`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const PartnerBlock = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
